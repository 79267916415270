import Vue from 'vue'
import Router from 'vue-router';
Vue.use(Router)
const router = new Router({
    routes: []
})
console.log('pc端')
router.addRoutes([{
    path: '/',
    component: () => import('@/pages/pc/index')
}, {
    path: '*',
    redirect: '/'
}])

// if (window.isMobile()) {
//     console.log('移动端')
//     router.addRoutes([{
//         path: '/',
//         component: () => import('@/pages/mobile/index')
//     }, {
//         // openWeChat的短名
//         path: '/owp',
//         component: () => import('@/pages/mobile/openWeChatP')
//     }, {
//         path: '/openWeChat',
//         component: () => import('@/pages/mobile/openWeChatP')
//     }, {
//         // openWeChatM的短名
//         path: '/owm',
//         component: () => import('@/pages/mobile/openWeChatM')
//     }, {
//         path: '/openWeChatM',
//         component: () => import('@/pages/mobile/openWeChatM')
//     }, {
//         path: '*',
//         redirect: '/'
//     }])
// } else {
//     console.log('pc端')
//     router.addRoutes([{
//         path: '/',
//         component: () => import('@/pages/pc/index')
//     }, {
//         path: '*',
//         redirect: '/'
//     }])
// }
export default router